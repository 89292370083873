import { Carousel } from 'antd';
import React, { useEffect } from 'react';

function CarouselComponent({ titlePic, sourcesArr }) {

    return (
        <div className='md:pb-3'>
            <img src={titlePic} className="m-auto md:w-60 w-40 pt-3 pb-3 md:pb-5" />
            <Carousel autoplay effect="fade">
                {
                    sourcesArr.map((x, index) => {
                        if (x[1]) {
                            return (
                                <div className='md:h-[800px] h-[420px] flex justify-center'>
                                    <iframe className='md:h-[760px] md:w-[760px] h-96 w-[760px]' src={x[0]} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            )
                        }
                        return (
                            <div className='md:h-[800px] h-80 flex justify-center'>
                                <img src={x[0]} className='max-h-full m-auto' />
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    );
}

export default CarouselComponent;