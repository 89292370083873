import './App.css';
import { Carousel } from 'antd';
import { InstagramOutlined, YoutubeOutlined, AppleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import React from 'react';
import soberTitlePic from './img/Sober/SOBER.png';
import soberPic1 from './img/Sober/1.jpg';
import soberPic2 from './img/Sober/2.jpg';
import soberPic3 from './img/Sober/3.jpg';
import soberPic4 from './img/Sober/4.jpg';
import soberPic5 from './img/Sober/5.jpg';
import freezerTitlePic from './img/Freezer/FREEZER.png';
import freezerPic1 from './img/Freezer/1.jpg';
import freezerPic2 from './img/Freezer/2.jpg';
import freezerPic3 from './img/Freezer/3.jpg';
import freezerPic4 from './img/Freezer/4.jpg';
import freezerPic5 from './img/Freezer/5.jpg';
import freezerPic6 from './img/Freezer/6.jpg';
import freezerPic7 from './img/Freezer/7.jpg';

import CarouselComponent from './component/CarouselComponent';

import ReactGA from 'react-ga';
ReactGA.initialize('G-1E2TMPGM0X');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const sourcesSober = [
    ["https://www.youtube.com/embed/qqajzWb2O2Q", true],
    [soberPic1, false],
    [soberPic2, false],
    [soberPic3, false],
    [soberPic4, false],
    [soberPic5, false]
  ]

  const sourcesFreezer=[
    ["https://www.youtube.com/embed/4LnZ5jgyZhA",true],
    [freezerPic1, false],
    [freezerPic2, false],
    [freezerPic3, false],
    [freezerPic4, false],
    [freezerPic5, false],
    [freezerPic6, false],
    [freezerPic7, false],
  ]

  return (
    <div className="bg-black min-w-screen min-h-screen text-white select-none">
      <div className='mx-auto sm:max-w-2xl md:max-w-7xl p-10'>

        <div className='flex md:flex-row flex-col md:gap-5 gap-0 justify-center'>
          <div className='text-5xl md:text-7xl font-bold'>
            <div className='pb-2 flex justify-center'>Nanami7y</div>
            <div className='flex gap-5 justify-between'>
              <a href='https://www.instagram.com/nanami7y/' target="_blank" className='hover:text-yellow-500 no-underline text-white pr-4'><InstagramOutlined /></a>
              <a href='https://www.youtube.com/user/SamiDrowsy' target="_blank" className='hover:text-yellow-500 no-underline text-white pr-2'><YoutubeOutlined /></a>
              <a href='https://open.spotify.com/artist/0yRydBDABP4wKGKrN6zsQt?si=RtdKspvYTye1z0weiqiN8Q' target="_blank" className='hover:text-yellow-500 no-underline text-white pr-2 pt-1 md:pt-3 pl-2 md:w-20 w-16'><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" class="bi bi-spotify" viewBox="0 0 16 16">
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.669 11.538a.498.498 0 0 1-.686.165c-1.879-1.147-4.243-1.407-7.028-.77a.499.499 0 0 1-.222-.973c3.048-.696 5.662-.397 7.77.892a.5.5 0 0 1 .166.686zm.979-2.178a.624.624 0 0 1-.858.205c-2.15-1.321-5.428-1.704-7.972-.932a.625.625 0 0 1-.362-1.194c2.905-.881 6.517-.454 8.986 1.063a.624.624 0 0 1 .206.858zm.084-2.268C10.154 5.56 5.9 5.419 3.438 6.166a.748.748 0 1 1-.434-1.432c2.825-.857 7.523-.692 10.492 1.07a.747.747 0 1 1-.764 1.288z" />
              </svg></a>
              <a href='https://music.apple.com/artist/nanami7y/1538266562' target="_blank" className='hover:text-yellow-500 no-underline text-white'><AppleOutlined style={{ top: '-5px', position: 'relative' }} /></a>
            </div>
          </div>

        </div>
        <div className='text-5xl md:text-7xl font-bold flex flex-col'>
          <CarouselComponent titlePic={freezerTitlePic} sourcesArr={sourcesFreezer} />
          <CarouselComponent titlePic={soberTitlePic} sourcesArr={sourcesSober} />
        </div>
      </div>
    </div>
  );
}

export default App;
